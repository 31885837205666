import React, { ReactElement, ReactNode } from 'react'

const divStyle = { marginTop: 17 }
export default function TitleBar({
  title,
  children,
}: {
  title: string | ReactElement
  children?: ReactNode
}) {
  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="pull-right" style={divStyle}>
          {children}
        </div>

        <h3 className="page-header">{title}</h3>
      </div>
    </div>
  )
}
