// Store should be loaded by non-components like resources in runtime
// to prevent a circular dependency in babel.
// Example: const Store = require('../Store');
import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import { middleware as beesMiddleware } from 'redux-bees'
import crashReporter from './redux/middleware/raygunCrashLogger'
import logger from './redux/middleware/configureLogger'
import rootReducer from './redux'

const middlewares = [promiseMiddleware(), beesMiddleware(), thunkMiddleware]
const isNode = process.env.NODE_ENV === 'test'

middlewares.push(crashReporter)

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger())
}

const enhancers = compose(
  applyMiddleware(...middlewares),
  !isNode && (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
)

const store = createStore(rootReducer, enhancers)

export default store
export const dispatch = store.dispatch
export const getState = store.getState
export const replaceReducer = store.replaceReducer

