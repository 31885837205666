import _ from 'lodash'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import toastr from 'toastr'

import { bookmarks } from '../../lib/opsApi'

interface BookmarkProps {
  createBookmark: (value: any) => Promise<void>
  text?: string
  id?: string | number
  name?: string
  alertType?: string
  data?: any
}

// Create a bookmark for the current page. If a text prop is not set,
// the component will try to read the current breadcrumb's text.
export class Bookmark extends Component<{ breadcrumb: any } & BookmarkProps> {
  /* eslint-disable react/no-find-dom-node */
  getBreadcrumbText = () =>
    this.breadcrumbComponent &&
    (findDOMNode(this.breadcrumbComponent) as any).innerText
  /* eslint-enable react/no-find-dom-node */

  handleClick = async () => {
    try {
      const { createBookmark, text } = this.props
      const { pathname, search, hash } = window.location

      await createBookmark({
        text: text || this.getBreadcrumbText(),
        url: pathname + search + hash,
      })

      toastr.success('Bookmark saved.', 'Success')
    } catch (err: any) {
      toastr.error(err.message, 'Failure')
    }
  }

  refBreadcrumb = (ref: any) => (this.breadcrumbComponent = ref)
  breadcrumbComponent: any

  render() {
    const { text, breadcrumb: { component } = {} } = this.props

    return (
      <button
        type="button"
        className="btn btn-sm btn-default"
        onClick={this.handleClick}
      >
        <i className="fa fa-star" /> Bookmark
        {!text && component && (
          <div style={{ display: 'none' }}>
            {React.createElement(component, { ref: this.refBreadcrumb })}
          </div>
        )}
      </button>
    )
  }
}

export const mapProps = (state: any) => ({
  breadcrumb: _.last(state.breadcrumb.breadcrumbs),
})

export default connect(mapProps, { createBookmark: bookmarks.create })(Bookmark)
