import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '../Store'
import Config from '../Config'
import { getAuthToken } from './localStorage'
import { requireReload } from '../redux/reducers/reloadRequired'
import Pusher from 'pusher-js'
import { debounce } from 'lodash'

import { useNotifications } from '../hooks/useNotifications'

const onReload = ({message}) => dispatch(requireReload(message))

PusherBinding.propTypes = {
  user: PropTypes.object.isRequired
}

PusherBinding.defaultProps = {
  user: null
}

export default function PusherBinding({ user }) {
  const { refetchNotifications } = useNotifications()

  useEffect(() => {
    Pusher.logToConsole = process.env.REACT_APP_NODE_ENV === 'development'
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'mt1',
      disableStats: true,
      forceTLS: true,
      authEndpoint: `${Config.opsServiceHost()}/pusher/auth`,
      enabledTransports: ['ws'],
      auth: {
        headers: { 'X-Auth-Token': getAuthToken() }
      }
    })
    const env = process.env.REACT_APP_NODE_ENV
    const suffix = env === 'development' ? `-${process.env.REACT_APP_HOST_NAME}` : ''
    const channel = `ops-${env}${suffix}`
    const notificationsChannel = `private-${channel}-uuid-${user.id}`

    pusher.subscribe(channel).bind('reloadrequired-ops-prime', onReload)
    pusher.subscribe(notificationsChannel).bind('notification', debounce(refetchNotifications, 1000))

    return function cleanup() {
      pusher.disconnect()
    }
  }, [user, refetchNotifications])

  return (<></>)
}
