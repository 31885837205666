import React from 'react'
import { formValues } from 'redux-form'
import isEqual from 'lodash/isEqual'

interface FormValueProps{
    value: any,
    onUpdate: any,
    children: any,
}

export class FormValue extends React.Component<FormValueProps> {
  componentDidUpdate(prevProps:{value:string}) {
    const { value, onUpdate } = this.props
    if (onUpdate && !isEqual(prevProps.value, value)) {
      onUpdate(value, prevProps.value)
    }
  }

  render() {
    const { children, value } = this.props
    return children({ value })
  }
}

const Enhanced = formValues((props:{name:string}) => ({ value: props.name }))(FormValue)

export default Enhanced
