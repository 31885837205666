import React, { FC, useEffect } from 'react'
import $ from 'jquery'
import 'daterangepicker/daterangepicker.css'
import 'daterangepicker'

interface DateRangePickerProps {
  options: any
  onChange: (start: any, end: any, label: string) => void
}

const DateRangePicker: FC<DateRangePickerProps> = ({ options, onChange }) => {
  useEffect(() => {
    ;($('#daterangepicker') as any).daterangepicker(
      options,
      function (start: any, end: any, label: string) {
        onChange(start, end, label)
      }
    )
  }, [onChange])

  return (
    <input
      type="text"
      id="daterangepicker"
      className="block w-full p-3 border border-solid border-gray-400 rounded-md"
    />
  )
}

export default DateRangePicker
