import React from 'react'

// Ensures a component remounts when navigating to it via "PUSH" action (menu link) and the component is already mounted.
const withRouteRemount = (Component: any) =>
  class withRouteRemount extends React.Component {
    state = { action: 'PUSH', lastRefresh: new Date() }

    static getDerivedStateFromProps(
      nextProps: { location: { action: string } },
      prevState: { action: string; lastRefresh: any }
    ) {
      const { action } = nextProps.location
      if (prevState.action !== action) {
        return {
          action,
          lastRefresh: action === 'PUSH' ? new Date() : prevState.lastRefresh,
        }
      }
      return null
    }

    render() {
      return <Component key={this.state.lastRefresh} {...this.props} />
    }
  }

export default withRouteRemount
