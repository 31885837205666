import React from 'react'
import { Radio } from 'react-bootstrap'
import FieldFeedback from './FieldFeedback'
import { Meta } from './ValidatedField'

interface Options {value:any, label:string, disabled:boolean}

interface RadioGroupProps{
    name:string,
    onChange:()=>void,
    value:any,
    options : Options[]
}

interface RadioGroupFieldProps{
    input: HTMLFormElement,
    meta: Meta,
    name:string,
    options : Options[]
}

export default function RadioGroup({ name, onChange, value, options = [] }:RadioGroupProps) {

  const radios = options.map(option => (
    <Radio
      key={option.value}
      name={name}
      onChange={onChange}
      value={option.value}
      checked={value === option.value}
      disabled={option.disabled}
      inline
    >
      {option.label}
    </Radio>
  ))

  for (let i = radios.length; i > 0; i--) {
    radios.splice(i, 0, <></>)
  }

  return radios
}

const feedbackStyle = { display: 'inline' }
const radioStyle = { marginLeft: 10 }
RadioGroup.Field = ({ input, meta, name, options = []}:RadioGroupFieldProps) => {
  const [head, ...tail] = options.map(option => (
    <Radio
      key={option.value}
      name={name}
      onChange={input.onChange}
      value={option.value}
      checked={input.value === option.value}
      disabled={option.disabled}
      style={radioStyle}
      inline
    >
      {option.label}
    </Radio>
  ))

  for (let i = tail.length; i > 0; i--) {
    tail.splice(i, 0, <></>)
  }

  return (
    <React.Fragment>
      <FieldFeedback meta={meta} style={feedbackStyle}>
        {head}
      </FieldFeedback>{' '}
      {tail}
    </React.Fragment>
  )
}
