import _ from 'lodash'
import $ from 'jquery'
import React from 'react'
import createReactClass from 'create-react-class'
import Autosuggest from 'react-autosuggest'
import 'jquery-ui/ui/position'
import './Typeahead.css'

interface TypeheadProps {
  options?: any[]
  value?: string
  onChange?: () => void
  maxSuggestions?: number
}

export default createReactClass<TypeheadProps>({
  displayName: 'Typeahead',

  getDefaultProps() {
    return { maxSuggestions: 5, value: '' }
  },

  getInitialState() {
    return { suggestions: this.getSuggestions(this.props.value) }
  },

  componentDidMount() {
    this.fixSuggestionsPosition()
  },

  componentDidUpdate() {
    this.fixSuggestionsPosition()
  },

  fixSuggestionsPosition() {
    if (process.env.NODE_ENV === 'test') {
      return
    }
    // I feel like there should be a better way to do this.
    window.requestAnimationFrame(() => {
      if (this.autosuggest == null) {
        return
      }

      const menu = $(this.autosuggest.suggestionsContainer)
      const input = $(this.autosuggest.input)

      if (!menu.length) {
        return
      }
      //position accepts 0 args however we are not removing this code as we are not sure about the impact of removing
      menu.position({
        my: 'left top',
        at: 'left bottom',
        of: input,
        collision: 'fit',
      })
    })
  },

  handleSuggestionsUpdateRequested({ value }: any) {
    this.setState({ suggestions: this.getSuggestions(value) })
  },

  handleSuggestionsClearRequested() {
    this.setState({ suggestions: [] })
  },

  getSuggestionValue(suggestion: any) {
    return suggestion
  },

  renderSuggestion(suggestion: any) {
    return suggestion
  },

  getSuggestions(value = '') {
    value = value && value.trim().toLowerCase()
    return (
      (value &&
        _.take(
          this.props.options.filter((o: any) =>
            o.toLowerCase().includes(value)
          ),
          this.props.maxSuggestions
        )) ||
      []
    )
  },

  shouldRenderSuggestions(value = '') {
    this.fixSuggestionsPosition()
    return value && value.trim().length > 0
  },

  handleChange(e: Event, { newValue }: any) {
    e.preventDefault()
    this.props.onChange(newValue)
  },

  handleSuggestionSelected(e: Event, { suggestionValue }: any) {
    e.preventDefault()
    this.props.onChange(suggestionValue)
  },

  render() {
    const {
      value,
      /* eslint-disable no-unused-vars */
      options,
      maxSuggestions,
      /* eslint-enable no-unused-vars */ ...rest
    } = this.props

    const theme = {
      container: 'autosuggest dropdown',
      containerOpen: 'open',
      input: 'autosuggest-input form-control',
      suggestionsContainer: 'autosuggest-suggestions dropdown-menu',
      suggestion: 'suggestion',
      suggestionFocused: 'active',
    }

    const inputProps = {
      value: value,
      className: 'form-control',
      ...rest,
      onChange: this.handleChange,
    }

    // TODO: keyboard shortcuts don't work
    return (
      <Autosuggest
        ref={(ref) => (this.autosuggest = ref)}
        suggestions={this.getSuggestions(value)}
        onSuggestionsFetchRequested={this.handleSuggestionsUpdateRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        inputProps={inputProps}
        theme={theme}
      />
    )
  },
})
