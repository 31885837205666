import React from 'react'
import { Field } from 'redux-form'
import ValidatedField, { CustomFieldProps, Placement } from './ValidatedField'
import './ios7.css'

const switchStyle = { fontSize: '25px' }
interface SwitchFieldProps{
    name: string;
    placement : Placement;
    normalize:any;
}


export default class SwitchField extends React.Component<SwitchFieldProps> {
  constructor(props:SwitchFieldProps) {
    super(props)
    this.renderSwitch = this.renderSwitch.bind(this)
  }

  renderSwitch(field:CustomFieldProps) {
    const { name, placement, ...rest } = this.props

    return (
      <ValidatedField name={name} field={field} placement={placement}>
        <label className="ios7-switch" style={switchStyle}>
          <input
            type="checkbox"
            {...field.input}
            disabled={field.meta.submitting}
            checked={field.input.value}
            {...rest}
          />
          <span />{' '}
          {/* This useless element is required for ios7-switch to work */}
        </label>
      </ValidatedField>
    )
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderSwitch}
        normalize={this.props.normalize}
      />
    )
  }
}

