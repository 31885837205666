import React, { ReactNode } from 'react'
import Tooltip from './Tooltip'

interface FormGroupProps {
  label?: any
  htmlFor?: string
  children?: any
  labelCols?: number
  controlCols?: number
}

export default function FormGroup(props: FormGroupProps) {
  const { label, children, labelCols, controlCols, htmlFor, ...rest } = props

  return (
    <div className="form-group" {...rest}>
      <label className={`control-label col-xs-${labelCols}`} htmlFor={htmlFor}>
        {label}
      </label>
      <div className={`col-xs-${controlCols}`}>{children}</div>
    </div>
  )
}

FormGroup.WithTooltip = (props: { title: string; label: ReactNode }) => {
  const { title, label, ...rest } = props
  return (
    <FormGroup {...rest} label={<Tooltip title={title}>{label}</Tooltip>} />
  )
}
