import React, { FC } from 'react'

interface DeliveryDetailLineProps {
  label: string
  field: { value?: string } | string
}

const divStyle: React.CSSProperties = { marginBottom: 0 }

const DeliveryDetailLine: FC<DeliveryDetailLineProps> = ({ label, field }) => {
  return (
    <div className="form-group" style={divStyle}>
      <label className="col-xs-3 control-label">{label}</label>
      <div className="col-xs-9 form-control-static">
        {(field && typeof field === 'object' && field.value) || field}
      </div>
    </div>
  )
}

export default DeliveryDetailLine
