import React, { ReactNode } from 'react'

const headerStyle = { marginBottom: 0 }
export default function Loader({ children }: { children?: ReactNode }) {
  return (
    <div className="row">
      <div className="col-xs-12">
        <h3 className="page-header" style={headerStyle}>
          {children}
        </h3>
        <p>
          <i className="fa fa-spin fa-refresh" /> Loading...
        </p>
      </div>
    </div>
  )
}
