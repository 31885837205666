import React, { ReactChild } from 'react'
import * as BS from 'react-bootstrap'

interface FormFieldProps {
  label: string,
  children: ReactChild,
  labelCols: number,
  fieldCols: number
}

export default function FormField({
  label,
  children,
  labelCols = 4,
  fieldCols = 8,
  ...rest
}:FormFieldProps) {
  return (
    <BS.FormGroup>
      <BS.Col componentClass={BS.ControlLabel} xs={labelCols} {...rest}>
        {label}
      </BS.Col>
      <BS.Col xs={fieldCols}>{children}</BS.Col>
    </BS.FormGroup>
  )
}
