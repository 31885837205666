import { ReactNode } from 'react'
import { reduxForm, Field } from 'redux-form'

interface ToRenderPropProps {
  children: (props: any) => ReactNode
}

const toRenderProp = ({ children, ...rest }: ToRenderPropProps) =>
  children(rest)
export default reduxForm({})(toRenderProp as any)

export { Field }
