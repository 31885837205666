import React from 'react'
import { withHandlers } from 'recompose'
import Tooltip from './Tooltip'

interface PrettyCheckBoxProps {
  value?: boolean
  checked?: boolean
  title?: string
  iconType?: string
  name: string
  onClick: () => void
}

export function PrettyCheckBox(props: PrettyCheckBoxProps) {
  const { name, onClick, ...rest } = props
  return (
    <a href="#" onClick={onClick}>
      <Tooltip id={name} {...rest} />
    </a>
  )
}

const onClick = (props: any) => (e: Event) => {
  e.preventDefault()
  const val = props.value || props.checked
  props.onChange(!val)
}

export default withHandlers({ onClick })(PrettyCheckBox)
