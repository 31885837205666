import React, { ReactElement } from 'react'
import Tooltip from '../Tooltip'

export type Placement = 'top' | 'bottom' | 'left' | 'right'
export interface Meta {
  touched: boolean
  error: any
  warning: string
  submitting: boolean
}
export interface CustomFieldProps {
  input: any
  meta: any
}

interface ValidatedFieldProps {
  name: string
  placement?: Placement
  field: CustomFieldProps
  children: ReactElement
}

export default function ValidatedField({
  name,
  placement = 'right',
  children,
  field,
}: ValidatedFieldProps) {
  const { touched, error } = field.meta

  return (
    <Tooltip
      id={`${name}_tooltip`}
      title={touched && error}
      hide={!(touched && error)}
      placement={placement}
    >
      <div className={(touched && error && 'has-error') || 'valid'}>
        {children}
      </div>
    </Tooltip>
  )
}
