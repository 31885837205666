import React, { createContext, ReactNode, useContext, useState } from 'react'

const ModalContext = createContext<any>(null)

interface ModalProviderProps {
  children: (value: any) => ReactNode
}

export default function ModalProvider({ children }: ModalProviderProps) {
  const [modal, setModal] = useState(null)

  return (
    <ModalContext.Provider value={setModal}>
      {children(modal)}
    </ModalContext.Provider>
  )
}

export function useModal() {
  return useContext(ModalContext)
}
