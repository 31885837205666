// NOTE: lazyLoad() will replace this, but it isn't ready yet.
// TODO: maybe implement lazyLoad() - rcrichlow - 12/5/24
//
// AsyncComponent enables code-splitting of page/route-level components, but
// you still needed to implement a separate Loading... state inside the component
// to handle data fetching. The lazyLoad() pattern allows you to use the same Loading...
// state for async operations that are needed to render the component, it doesn't
// draw a distinction between code-splitting vs other types of async operations.
import React, { Component, ComponentType } from 'react';

type ImportComponent = () => Promise<{default: ComponentType}>

export default function asyncComponent(importComponent: ImportComponent) {
  let C: ComponentType

  return class AsyncComponent extends Component {
    private _isMounted = false

    async componentDidMount() {
      this._isMounted = true
      const { default: component } = await importComponent();
      C = component
      this._isMounted && this.forceUpdate()
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      return C ? <C {...this.props} /> : null;
    }
  };
}
