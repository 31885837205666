import React from 'react'
import Datetime, { DatetimepickerProps } from 'react-datetime'
import { Field } from 'redux-form'

import ValidatedField, { Placement } from './ValidatedField'
import defaultIsValidDate from '../../../lib/modernDateValidator'

interface DatetimeFieldProps{
    name: string;
    placement ?: Placement;
    placeholder?: string;
    isValidDate?: any;
}

export default class DatetimeField extends React.Component<DatetimeFieldProps & DatetimepickerProps> {
  renderDatetimeField = (field:any) => {
    const { name, placeholder, placement, isValidDate=defaultIsValidDate, ...rest } = this.props
    const { submitting } = field.meta

    return (
      <ValidatedField name={name} field={field} placement={placement}>
        <Datetime
          value={field.input.value}
          onChange={field.input.onChange}
          inputProps={{ disabled: submitting, placeholder }}
          isValidDate={isValidDate}
          closeOnSelect
          {...rest}
        />
      </ValidatedField>
    )
  }

  render () {
    return (
      <Field
        component={this.renderDatetimeField}
        {...this.props}
      />
    )
  }
}

