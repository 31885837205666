import React from 'react';
import { FormGroup, HelpBlock } from 'react-bootstrap';
import capitalize from 'lodash/capitalize';

interface FieldValidatorProps {
    meta: {
      touched: boolean;
      error?: string;
      warning?: string;
    };
  }

export default function FieldValidator({meta}:FieldValidatorProps) {
  const {
    touched, error, warning 
  } = meta;

  function validationState() {
    if (touched && error) return 'error';
    if (touched && warning) return 'warning';
    return null;
  }

  return (
    <FormGroup validationState={validationState()} bsClass="">
      <HelpBlock>{touched && capitalize(error || warning)}</HelpBlock>
    </FormGroup>
  );
}

