import React from 'react'
import { withHandlers } from 'recompose'

interface SortableTableHeaderProps {
  column: any
  onSort: () => void
  sort: string
  sortOrder: string
  handleSort?: () => void
}

export function SortableTableHeader(props: SortableTableHeaderProps) {
  const { column, sort, sortOrder, handleSort } = props
  const sortClass =
    column.type === 'string'
      ? `fa fa-sort-alpha-${sortOrder}`
      : `fa fa-sort-${sortOrder}`

  return (
    <th
      key={column.value}
      className="text-center sortable"
      onClick={handleSort}
    >
      {column.label} {sort === column.value && <i className={sortClass} />}
    </th>
  )
}

export const handleSort = (props: any) => (e: Event) => {
  e.preventDefault()
  props.onSort(props.column)
}

export default withHandlers({ handleSort })(SortableTableHeader)
