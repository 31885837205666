import React, { Component } from 'react'
import Select from 'react-select'

export interface SelectDropDownProps {
  options?: any
  onChange: (value: any) => void
  onBlur?: (value: any) => void
  value?: any
  simpleValue?: boolean
  multi?: boolean
  showOneOptionAsLabel?: boolean
  isLoading?: boolean
  noResultsText?: any
  clearable?: boolean
  disabled?: boolean
  componentClass?: any
  placeholder?: string
  searchable?: boolean
  className?: string
  name?: string
}

export default class SelectDropDown extends Component<SelectDropDownProps> {
  setSelectedValue = (props: SelectDropDownProps) => {
    const { options, showOneOptionAsLabel, value, onChange } = props
    if (!showOneOptionAsLabel) {
      return
    }
    // If there is only one option, select it unless it is the already-selected option.
    if (options && options.length === 1) {
      const defaultValue = options[0].value
      if (value === defaultValue) {
        // eslint-disable-line eqeqeq
        return
      }

      onChange && onChange(defaultValue)
    }
  }

  componentDidMount() {
    this.setSelectedValue(this.props)
  }

  componentDidUpdate(_prevProps: any) {
    this.setSelectedValue(this.props)
  }

  handleChange = (value: string | string[], items: any) => {
    const { onChange, multi, simpleValue } = this.props

    if (multi) {
      if (simpleValue) {
        value = value === '' ? [] : (value as string).split(',')
      } else {
        value = items
      }
    }

    onChange && onChange(value)
  }

  render() {
    const {
      options,
      value,
      multi = false,
      simpleValue = true,
      showOneOptionAsLabel = false,
      onBlur,
      isLoading = false,
      clearable = false,
      // disabled = false,
      componentClass = Select,
      ...rest
    } = this.props
    const ComponentClass = componentClass
    const handleBlur = !multi && onBlur ? () => onBlur(value) : () => {}

    if (showOneOptionAsLabel && options && options.length === 1) {
      return (
        <div className="form-control-static">
          <div style={{ marginTop: 2 }}>{options[0].label}</div>
        </div>
      )
    }

    if (isLoading) {
      return (
        <ComponentClass
          {...rest}
          disabled
          placeholder={<i className="fa fa-refresh fa-spin" />}
        />
      )
    }

    return (
      <ComponentClass
        options={options}
        multi={multi}
        clearable={clearable}
        backspaceRemoves={clearable}
        deleteRemoves={clearable}
        value={value || ''}
        simpleValue={simpleValue}
        {...rest}
        onBlur={handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}
