import _ from 'lodash'
import React from 'react'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { bindResource } from 'redux-supermodel'
import { Link } from 'react-router'

import FormGroup from './FormGroup'
import InputField from './Fields/InputField'
import DeliveryDetailLine from './DeliveryDetailLine'
import withResource from './DropDowns/ResourceDropDown'
import ControlledField from './Fields/ControlledField'
import { ftpConfigurations } from '../../lib/opsApi'

const FtpDropDown = withResource(ftpConfigurations)

const styles = {
  select: { marginTop: 0 },
  well: {
    Position: 'relative',
    marginBottom: 0,
    padding: 7,
    WordBreak: 'break-all',
  },
  edit: { Position: 'absolute', zIndex: 1, top: 0, right: 0 },
}

interface FTPSelectFormProps {
  change?: () => void
  createNewDelivery?: boolean
}

export const FTPSelectForm = createReactClass<FTPSelectFormProps>({
  displayName: 'FTPSelectForm',

  handleCreateNew(e: Event) {
    e.preventDefault()
    this.props.change('collectionMethodValue', null)
    this.props.change('createNewDelivery', true)
  },

  handleCreateCancel(e: Event) {
    e.preventDefault()
    this.props.change('ftp_configuration_attributes', null)
    this.props.change('createNewDelivery', false)
  },

  renderAddConfigurationsLink() {
    return (
      <span>
        <em>Don&apos;t see what you are looking for?</em>
        &nbsp;
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a onClick={this.handleCreateNew}>Add a new FTP configuration</a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </span>
    )
  },

  render() {
    const {
      collectionMethodType,
      collectionMethodValue,
      createNewDelivery,
      dce,
      ftpConfigurations,
    } = this.props
    if (createNewDelivery) {
      return (
        <div>
          <FormGroup label="Name*" labelCols={3} controlCols={9}>
            <InputField
              name="ftp_configuration_attributes.name"
              component="input"
              type="text"
              className="form-control"
              autoFocus
            />
          </FormGroup>

          <FormGroup label="Location*" labelCols={3} controlCols={9}>
            <InputField
              name="ftp_configuration_attributes.location"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup label="Folder" labelCols={3} controlCols={9}>
            <InputField
              name="ftp_configuration_attributes.folder"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup label="User Name*" labelCols={3} controlCols={9}>
            <InputField
              name="ftp_configuration_attributes.user_name"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup label="Password*" labelCols={3} controlCols={9}>
            <InputField
              name="ftp_configuration_attributes.password"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup labelCols={3} controlCols={9}>
            <button
              id="existing"
              type="button"
              className="btn btn-link"
              onClick={this.handleCreateCancel}
            >
              Use Existing FTP
            </button>
          </FormGroup>
        </div>
      )
    }

    const ftp =
      ftpConfigurations &&
      ftpConfigurations.ready &&
      _.find(ftpConfigurations.payload && ftpConfigurations.payload.data, {
        id: collectionMethodValue,
      })

    return (
      <div>
        <div className="form-group">
          <div className="col-xs-6 col-xs-offset-3">
            <ControlledField
              name="collectionMethodValue"
              component={FtpDropDown}
              disabled={!collectionMethodType}
              style={styles.select}
              noResultsText={this.renderAddConfigurationsLink()}
              placeholder="Select FTP configuration..."
            />
          </div>
          <div className="col-xs-3">
            <button
              id="add"
              type="button"
              className="btn btn-default pull-right"
              onClick={this.handleCreateNew}
            >
              <i className="fa fa-plus" /> Add FTP
            </button>
          </div>
        </div>

        {ftp && (
          <div className="well" style={styles.well}>
            <Link
              to={{
                pathname: `/ftpconfigurations/${ftp.id}`,
                query: { dce_id: dce && dce.id },
              }}
              className="btn btn-link"
              style={styles.edit}
            >
              Edit
            </Link>
            <DeliveryDetailLine label="Location" field={ftp.location} />
            <DeliveryDetailLine label="Folder" field={ftp.folder} />
            <DeliveryDetailLine label="User Name" field={ftp.user_name} />
            <DeliveryDetailLine label="Password" field={ftp.password} />
          </div>
        )}
      </div>
    )
  },
})

const selector = formValueSelector('dataCollectionEffortFileDetails')
export function mapStateToProps(state: {}) {
  return {
    collectionMethodValue: selector(state, 'collectionMethodValue'),
  }
}

export function mount({ fetchFtpConfigurations, organization_id }: any) {
  return fetchFtpConfigurations({ organization_id })
}

const resources = { ftpConfigurations }
export const ResourceBound = bindResource(resources, { mount })(FTPSelectForm)
export default connect(mapStateToProps)(ResourceBound)
