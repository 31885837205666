import React from 'react'
import classNames from 'classnames'
import AlertItem from './AlertItem'
import { GQLNotification } from './types'

interface AlertPanelProps {
  data: any,
  type?: string,
  bsClass?: string,
  itemProps?: any,
  onReadAll: () => void,
}

export default function AlertPanel({
  data,
  onReadAll,
  type = 'messages',
  bsClass = 'default',
  itemProps = null,
  ...rest
}: AlertPanelProps) {
  const notifications: GQLNotification[] = data?.collection || []
  const unread: number = data?.metadata?.unread || 0
  const total: number = data?.metadata?.totalCount || 0

  const markAllReadClasses = classNames({
    "btn-xs bg-transparent border-none hover:underline": true,
    "text-white hover:text-white": type === "comments",
    "text-gray-700 hover:text-gray-700": type !== "comments"
  })

  const handleReadAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    onReadAll()
    e.preventDefault()
  }

  return (
    <div className={`panel panel-${bsClass || 'default'}`}>
      <div className="panel-heading">
        <div className="row">
          <div className="col-xs-12 flex justify-between items-center">
            <p className="form-control-static">
              <b className="uppercase">{type}</b> -
              <small className="ml-2">
                {unread} UNREAD of {total}
              </small>
            </p>

            <form>
              {!!unread && (
                <button
                  className={markAllReadClasses}
                  onClick={handleReadAll}
                >
                  <i className="fa fa-envelope-open-o mr-2" />Mark all as read
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
      <ul className="list-group" data-testid="list-group">
        {notifications.map(notification => (
          <AlertItem
            key={notification.id}
            type={type}
            notification={notification}
            {...itemProps}
            {...rest}
          />
        ))}
      </ul>
    </div>
  )
}
