import React, { ReactNode } from 'react'
import { connect } from 'react-redux'

interface ModelProviderProps {
  model: any
  fetch: (value: any) => void
  params: any
  name: string
  children: (value: any) => ReactNode
}

export class ModelProvider extends React.Component<ModelProviderProps> {
  componentDidMount() {
    this.fetch()
  }

  componentDidUpdate() {
    const { busy, initialized } = this.props.model
    if (!busy && !initialized) {
      this.fetch()
    }
  }

  fetch = () => {
    this.props.fetch(this.props.params)
  }

  render() {
    const { children, model, name = 'model', ...rest } = this.props
    return children({ ...rest, [name]: model })
  }
}

export default function modelProvider(model: any) {
  const mapProps = (state: any) => ({ model: model(state) })
  const actions = {
    fetch: model.fetch,
    create: model.create,
    update: model.update,
    destroy: model.destroy,
    reset: model.reset,
    clearErrors: model.clearErrors,
  }

  return connect(mapProps, actions)(ModelProvider as any)
}
