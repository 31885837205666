import React from 'react'
import createReactClass from 'create-react-class'

interface RefreshButtonProps {
  onClick: () => void
}

export default createReactClass<RefreshButtonProps>({
  displayName: 'RefreshButton',

  handleClick(e: Event) {
    e.preventDefault()
    this.props.onClick()
  },

  render() {
    return (
      <button className="btn btn-primary btn-md" onClick={this.handleClick}>
        <i className="fa fa-refresh" /> Refresh
      </button>
    )
  },
})
