import React from 'react'
import {
  MentionsInput,
  Mention,
  SuggestionDataItem,
  DataFunc,
} from 'react-mentions'
import CommentInputStyle from './CommentInputStyle'

export function displayTransform(id: string, display: string) {
  return '@' + display.substring(0, 99)
}

interface CommentInputProps {
  value?: string
  onChange: (value: any) => void
  data: SuggestionDataItem[] | DataFunc | null
  [key: string]: any
}

export default function CommentInput(props: CommentInputProps) {
  const { value, onChange, data, ...rest } = props

  return (
    <MentionsInput
      value={value || ''}
      onChange={onChange}
      className="react-mentions"
      style={CommentInputStyle}
      allowSpaceInQuery
      autoComplete="off"
      {...rest}
    >
      <Mention displayTransform={displayTransform} trigger="@" data={data} />
    </MentionsInput>
  )
}
