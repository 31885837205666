import React from 'react'
import toastr from 'toastr'
import $ from 'jquery'

interface ResourceErrorProps {
  error?: Error | null
  hideInlineMessage?: boolean
  hideToastMessage?: boolean
  title?: string
}

export default class ResourceError extends React.PureComponent<ResourceErrorProps> {
  componentDidMount() {
    const { error, title = 'Failure', hideToastMessage } = this.props
    if (error && !hideToastMessage) {
      toastr.error(this.getMessage(error), title)
    }
  }

  componentDidUpdate(prevProps: ResourceErrorProps) {
    const { error, title, hideToastMessage } = this.props
    if (error && !hideToastMessage && prevProps.error !== error) {
      // Do not display a toast if there is already one showing for the same error
      if (
        $('div.toast.toast-error').filter(function () {
          return $(this).data('error') === error
        }).length
      ) {
        return
      }

      toastr.error(this.getMessage(error), title).data('error', error)
    }
  }

  getMessage(err: any) {
    err = (err.errors && err.errors._error) || err
    if (Array.isArray(err) && err.length > 1) {
      err = (
        <ul>
          {err.map((errMessage, index) => (
            <li key={index}>{errMessage}</li>
          ))}
        </ul>
      )
    }

    return err
  }

  render() {
    const { error, hideInlineMessage } = this.props
    if (!error) {
      return false
    }

    const msg = this.getMessage(error)
    return msg && !hideInlineMessage ? (
      <span className="text-danger">{msg}</span>
    ) : null
  }
}
