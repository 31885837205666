import React from 'react'
import SubmissionError from 'redux-form/lib/SubmissionError'
import ResourceError from './Common/ResourceError'

interface ErrorPageProps {
  location: {
    state: {
      error: SubmissionError,
    }
  }
}

export default function ErrorPage(props: ErrorPageProps): React.JSX.Element {
  const {
    location: { state: { error } },
  } = props

  if (error) {
    console.log(error) // eslint-disable-line no-console
  }

  return (
    <div className='panel panel-danger'>
      <div className='panel-heading'>
        An error occurred
      </div>
      <div className='panel-body'>
        <ResourceError error={error || 'We\'re sorry, the application stopped working.'} />
      </div>
    </div>
  )
}
