export enum AlertCategory {
  WARNINGS = 'WARNINGS',
  MESSAGES = 'MESSAGES',
  COMMENTS = 'COMMENTS',
}

export enum NotificationType {
  CLIENT_PRODUCT_ACTIVITY = 'CLIENT_PRODUCT_ACTIVITY',
  CLIENT_PRODUCT_COMMENT = 'CLIENT_PRODUCT_COMMENT',
  DATA_COLLECTION_PRODUCT_REQUEST = 'DATA_COLLECTION_PRODUCT_REQUEST',
  DATA_COLLECTION_EFFORT = 'DATA_COLLECTION_EFFORT',
  DATA_COLLECTION_EFFORT_DUE_DATE = 'DATA_COLLECTION_EFFORT_DUE_DATE',
  FIM_DATA_COLLECTION_EFFORT_DUE_DATE = 'FIM_DATA_COLLECTION_EFFORT_DUE_DATE',
  MACHINE_READABLE_FILE_SUBMIT_REQUEST = 'MACHINE_READABLE_FILE_SUBMIT_REQUEST',
  NETWORK_MAPPING = 'NETWORK_MAPPING',
  PROJECT_MAPPING = 'PROJECT_MAPPING',
  CLIENT_PRODUCT = 'CLIENT_PRODUCT',
  DCE_GENERATION_TRANSACTION = 'DCE_GENERATION_TRANSACTION',
  PRODUCT_REQUEST_RECEIVED = 'PRODUCT_REQUEST_RECEIVED',
  COMMENT_MODERATION = 'COMMENT_MODERATION',
  NEXT_ACTION_REMINDER = 'NEXT_ACTION_REMINDER',
  NEXT_ACTION_REMINDER_MEDICARE_ADVANTAGE = 'NEXT_ACTION_REMINDER_MEDICARE_ADVANTAGE',
  CARRIER_APPROVAL_REQUEST_COMMENT = 'CARRIER_APPROVAL_REQUEST_COMMENT',
}

export interface GQLAlert<T = any> {
  displayName: string,
  gravatarUrl: string,
  /**
   * @todo could a union type be better than a generic here? - rcrichlow - 12/19/24
   */
  data: T,
  message: string,
  systemGenerated: boolean,
  type: NotificationType,
  category: AlertCategory,
  isImportant: boolean,
}

export interface GQLNotification {
  id: string,
  createdAt: Date,
  hasViewed: boolean,
  alert: GQLAlert,
}

export interface Bookmark {
  created_at: Date,
  id: number,
  text: string,
  url: string,
  user_id: string,
  /**
   * @todo is this actually needed? there is a fallback with this property in the BookmarkList HTML,
   * but it is unclear why based on how the props/state are being mapped - rcrichlow - 12/18/24
   */
  data?: Bookmark,
}
