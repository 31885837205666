import React from 'react'
import { MatchHighlight } from '../withHighlight'

interface HighlightCellProps {
  column: any
  columnProps: any
  value: string
}

export default function HighlightCell(props: HighlightCellProps) {
  const { column, columnProps, value } = props

  if (value == null) return null
  const item = columnProps.rest.filtered.find(
    (x: { id: any }) => x.id === column.id
  )

  return (
    <div className="whitespace-pre-line">
      <MatchHighlight match={item && item.value} children={value} />
    </div>
  )
}
