import React, { ReactNode } from 'react'
import { Table } from 'react-bootstrap'
import './FixedTable.css'

interface FixedTableProps {
  colgroup: ReactNode
  thead: ReactNode
  rows?: any[]
  striped?: boolean
  bordered?: boolean
  condensed?: boolean
  hover?: boolean
}

export default function FixedTable(props: FixedTableProps) {
  const {
    colgroup,
    thead,
    rows,
    striped,
    bordered,
    condensed,
    hover,
    ...rest
  } = props

  return (
    <div className="table table-fixed-header">
      <div className="table-header">
        <Table
          striped={striped}
          bordered={bordered}
          condensed={condensed}
          hover={hover}
        >
          {colgroup}
          {thead}
        </Table>
      </div>
      <div className="table-body" {...rest}>
        <Table
          striped={striped}
          bordered={bordered}
          condensed={condensed}
          hover={hover}
        >
          {colgroup}
          <tbody>{rows}</tbody>
        </Table>
      </div>
    </div>
  )
}
