import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import withCurrentUser from './Common/withCurrentUser'
import { User } from './types'

interface ProfileProps {
  currentUser: User,
  size?: number,
}

export function Profile (props: ProfileProps) {
  const { currentUser, size = 40 } = props

  if (!currentUser || !currentUser.gravatar_url) {
    return null
  }

  return (
    <div>
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='profileEmailTooltip'>{currentUser.email}</Tooltip>}
        rootClose
      >
        <img
          src={`${currentUser.gravatar_url}&s=${size}`}
          style={{width: size, height: size}}
          className='img-circle'
          alt=""
        />
      </OverlayTrigger>
    </div>
  )
}

export default withCurrentUser(Profile)
