import React from 'react'
import { compose, defaultProps } from 'recompose'
import { connect } from 'react-redux'
import { getRequestInfo } from 'redux-bees'
import Select from './Select'

export const defaultOptionFormatter = (option: any) => ({
  ...option,
  value: option.id,
  label: option.attributes.label || option.attributes.name,
})

export const JsonapiSelect = (props: any) => {
  const { componentClass: Component, request, ...rest } = props

  return (
    <Component
      {...rest}
      isLoading={request.isLoading}
      options={request.result && request.result.map(props.optionFormatter)}
    />
  )
}

export const mapProps = (state: any, props: any) => ({
  request: getRequestInfo(state, props.getter, props.getterArgs),
})

export default compose(
  defaultProps({
    componentClass: Select,
    optionFormatter: defaultOptionFormatter,
    getterArgs: [],
  }),
  connect(mapProps)
)(JsonapiSelect)
