import React from 'react'
import Tooltip from '../Tooltip'
import { MatchHighlight } from '../withHighlight'

interface TooltipCellProps {
  column: any
  columnProps: any
  value?: any
  index?: number
}

export default function TooltipCell(props: TooltipCellProps) {
  const { column, columnProps, value, index } = props

  if (!value) return null

  const item = columnProps.rest.filtered.find(
    (x: { id: any }) => x.id === column.id
  )

  const highlighted = (
    <MatchHighlight match={item && item.value} children={props.value} />
  )

  return (
    <Tooltip id={`tooltipCell${index}`} title={highlighted} placement="left">
      {highlighted}
    </Tooltip>
  )
}
