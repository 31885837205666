import React, { MouseEventHandler } from 'react'
import Datetime from 'react-datetime'
import {
  Button,
  InputGroup,
  FormControl,
  FormControlProps,
} from 'react-bootstrap'
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  renameProp,
  withHandlers,
} from 'recompose'
import { withFeedback } from './FieldFeedback'
import MaskedInput from 'react-text-mask'

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const renderInput =
  (ownProps: any) => (inputProps: any, open: MouseEventHandler<Button>) => {
    return (
      <>
        <InputGroup>
          <InputGroup.Button>
            <Button onClick={open} disabled={ownProps.disabled}>
              <i className="fa fa-calendar" />
            </Button>
          </InputGroup.Button>
          <FormControl
            componentClass={MaskedInput}
            mask={dateMask}
            placeholder="MM/DD/YYYY"
            {...inputProps}
          />
        </InputGroup>
        {ownProps.clearable && !ownProps.disabled && inputProps.value && (
          <FormControl.Feedback>
            <span>
              <Button
                bsStyle="link"
                onClick={ownProps.clear}
                className="btn-feedback"
              >
                &times;
              </Button>
            </span>
          </FormControl.Feedback>
        )}
      </>
    )
  }
export const clear =
  ({ onChange }: { onChange: (value: any) => void }) =>
  () =>
    onChange(null)
const DateTime = compose<any, any>(
  defaultProps({
    dateFormat: 'MM/DD/YYYY',
    timeFormat: false,
    utc: true,
    showInput: true,
    closeOnSelect: true,
  }),
  renameProp('showInput', 'input'),
  withHandlers({ clear }),
  // These are separate because renderInput() calls clear()
  withHandlers({ renderInput }),
  setDisplayName('DateTime')
)(Datetime as any)

;(Datetime as any).Field = compose<any, any>(
  mapProps(
    ({ input: { value, onChange }, ...rest }: { input: FormControlProps }) => ({
      value,
      onChange,
      ...rest,
    })
  ),
  withFeedback
)(DateTime as any)
export default DateTime
