import React from 'react'

const pattern = /@\[([^)]+)\]\(([A-Za-z0-9-]+)\)/

interface ParsedCommentProps {
  comment: string
  style: any
}

export default function ParsedComment(props: ParsedCommentProps) {
  const { comment, ...rest } = props
  if (!comment) {
    return null
  }

  const children = []
  let match: any
  let str = comment
  let limit = 0

  while ((match = str.match(pattern)) && limit < 10) {
    const [mention, name] = match
    const before = str.substring(0, match.index)
    str = str.substring(match.index + mention.length)

    children.push(before)
    children.push(<b key={limit}>@{name}</b>)
    limit++
  }

  children.push(str)

  return <p {...rest}>{children}</p>
}
