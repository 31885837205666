import React, { Component, ErrorInfo } from 'react'
import 'raygun4js'
import ErrorPanel from './ErrorPanel'

declare global {
  var Raygun: RaygunStatic;

  namespace NodeJS {
    interface Global {
      Raygun: RaygunStatic;
    }
  }
}

interface ErrorBoundaryState {
  error: Error | null,
  info: ErrorInfo | null,
}

export default class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state = { error: null, info: null }

  componentDidCatch (error: Error, info: ErrorInfo) {
    this.setState({ error, info })

    if (global.Raygun) {
      global.Raygun.send(error, info)
    }
  }

  clearError = () => this.setState({ error: null, info: null })

  render () {
    if (this.state.error) {
      return (
        <ErrorPanel error={this.state.error} info={this.state.info} onClose={this.clearError} />
      )
    }

    return this.props.children
  }
}
