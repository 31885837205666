import React from 'react'
import { connect } from 'react-redux'
import { breadcrumbify } from 'redux-breadcrumb-trail'

interface PatchBreadcrumbProps {
  title?: any
}

export function PatchBreadcrumb({ title = null }: PatchBreadcrumbProps) {
  if (!title) {
    return <i className="fa fa-refresh fa-spin" />
  }

  return <span>{title}</span>
}

export const mapStateToProps = (state: {
  breadcrumbPatch: { title: any }
}) => ({
  title: state.breadcrumbPatch.title,
})
export default connect(mapStateToProps)(breadcrumbify(PatchBreadcrumb))
