import React, { ReactNode } from 'react'
import { Modal } from 'react-bootstrap'

interface ConfirmDialogProps {
  /** The title, positioned in the confirmation's header */
  title: ReactNode
  /** The message body of the confirmation */
  body: ReactNode
  /** Shows the confirmation when true */
  show: boolean
  /** If true, shows a spinner because an operation is in progress */
  inProgress?: boolean
  /** Callback when the confirm button is clicked */
  onConfirm: () => void
  /** Callback when the dialog is closed */
  onClose: () => void
}

export default function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    title,
    body,
    show = false,
    inProgress = false,
    onConfirm,
    onClose,
  } = props

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <button id="close" className="btn btn-default" onClick={onClose}>
          No
        </button>
        <button
          id="confirm"
          className="btn btn-primary"
          autoFocus
          onClick={onConfirm}
          disabled={inProgress}
        >
          Yes <i className={inProgress ? 'fa fa-cog fa-spin' : ''} />
        </button>
      </Modal.Footer>
    </Modal>
  )
}
