import React from 'react'
import Loader from './Loader'

interface PlaceholderProps {
  children: (value: any) => void
  fetch: () => void
  fallback: any
}

export default class Placeholder extends React.Component<PlaceholderProps> {
  static defaultProps = {
    fallback: <Loader />,
  }

  state = { payload: null }

  async componentDidMount() {
    const res = await this.props.fetch()
    this.setState({ payload: res })
  }

  render() {
    const { payload } = this.state
    return payload ? this.props.children(payload) : this.props.fallback
  }
}
