import React from 'react'
import { Link } from 'react-router'

export default function Forbidden(): React.JSX.Element {
  return (
    <div className='panel panel-danger'>
      <div className='panel-heading'>
        Access Forbidden
      </div>
      <div className='panel-body'>
        You are not permitted to perform the requested operation. <Link to='/logout'>Logout</Link>
      </div>
    </div>
  )
}
