import React, { ReactElement, useState } from 'react'
import { NavLink } from 'redux-breadcrumb-trail'
import { Button, Dropdown } from 'react-bootstrap'
import classNames from 'classnames'

import ResourceError from '../Common/ResourceError'
import { useNotifications } from '../../hooks/useNotifications'

interface AlertsMenuProps {
  children: ReactElement
  labelClass: string
  icon: string
  totalUnread?: number
  dropdownId?: string
  linkTo?: {
    path: string
    text: string
  }
}

export default function AlertsMenu({
  children,
  labelClass,
  icon,
  dropdownId = '',
  totalUnread = 0,
  linkTo = { path: 'alerts/all', text: 'See all Alerts' },
}: AlertsMenuProps) {
  const [open, setOpen] = useState(false)

  const {
    notificationsQueries: {
      allCategoriesQuery: [, { loading, error, refetch }],
    },
  } = useNotifications()

  const unreadCountDisplay = () => {
    if (totalUnread === 0) return ''
    if (totalUnread > 99) return '99+'
    return totalUnread
  }

  const renderIcon = () => {
    const spanClasses = classNames({
      label: true,
      'label-pill': true,
      [labelClass]: true,
    })

    const iClasses = classNames({
      'mr-2': true,
      fa: true,
      [loading ? 'fa-refresh' : icon]: true,
      'fa-spin': loading,
    })

    return (
      <span className={spanClasses} style={{ fontSize: 18 }}>
        <i className={iClasses} />
        {unreadCountDisplay()}
      </span>
    )
  }

  const renderChildren = (childProps: any) => {
    const childElements = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        closeDropdown: () => setOpen(false),
        ...childProps,
      })
    )

    return <>{childElements}</>
  }

  const ChildElements = renderChildren

  return (
    <Dropdown
      id={dropdownId}
      componentClass="li"
      onToggle={() => setOpen(!open)}
      open={open}
    >
      <Dropdown.Toggle useAnchor noCaret>
        {renderIcon()}
      </Dropdown.Toggle>

      <Dropdown.Menu className="well" style={{ width: 497 }}>
        <div className="text-center mb-4">
          <NavLink
            to={linkTo.path}
            className="text-center"
            onClick={() => setOpen(false)}
          >
            {linkTo.text}
          </NavLink>
          <Button
            disabled={loading}
            onClick={() => refetch()}
            className={'pull-right'}
            bsStyle="link"
            bsSize="small"
          >
            <i className="fa fa-refresh" />
          </Button>
        </div>

        <form>
          <ResourceError error={error} />
        </form>

        <ChildElements />
      </Dropdown.Menu>
    </Dropdown>
  )
}
