import { lifecycle } from 'recompose'

/*
 * Resets the form when the show prop changes to false.
 * For redux-form components inside modal dialogs because sometimes
 * you want to separate the form reset behavior from the
 * mount/unmount behavior, e.g. data loading.
 */
export default lifecycle({
  componentDidUpdate(prevProps:{show:boolean,reset:()=>void}) {
    const { show, reset } = prevProps
    if (show && !this.props.show) {
      reset()
    }
  }
})
