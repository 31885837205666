import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface AbbrProps {
  id: string
  title: string
  children: any
}

/* Fancy React version of the classic <abbr> tag */
export default function Abbr(props: AbbrProps) {
  const { id, title, children, ...rest } = props
  const tooltip = <Tooltip id={id}>{title}</Tooltip>

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip} {...rest}>
      <span className="abbr">{children}</span>
    </OverlayTrigger>
  )
}
