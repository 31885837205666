import { browserHistory, useRouterHistory } from 'react-router'
import { createMemoryHistory } from 'history'

// TODO: getting type errors here that are likely related to version mismatches between the History
// library we're including and the one react-router is using. casting to `any` as a temporary measure.
// will need to address this when libraries start getting updated. - rcrichlow - 12/13/24

// Must use Memory History in Node.js because Browser History requires the DOM.
const history = typeof window === 'undefined' ? useRouterHistory(createMemoryHistory as any)() : browserHistory
export default history
export const goBack = history.goBack
