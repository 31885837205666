import _ from 'lodash'
import React from 'react'
import createReactClass from 'create-react-class'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { bindResource } from 'redux-supermodel'

import DeliveryDetailLine from './DeliveryDetailLine'
import withResource from './DropDowns/ResourceDropDown'
import FormGroup from './FormGroup'
import InputField from './Fields/InputField'
import ControlledField from './Fields/ControlledField'
import { websiteConfigurations } from '../../lib/opsApi'

interface WebsiteSelectFormProps {
  collectionMethodValue?: number
  websiteConfigurations?: any
  change: () => void
  createNewDelivery?: boolean
}

const SiteDropDown = withResource(websiteConfigurations)

const styles = {
  select: { marginTop: 0 },
  well: { marginBottom: 0, padding: 7, WordBreak: 'break-all' },
}

export const WebsiteSelectForm = createReactClass<WebsiteSelectFormProps>({
  displayName: 'WebsiteSelectForm',

  handleCreateNew(e: Event) {
    e.preventDefault()
    this.props.change('collectionMethodValue', null)
    this.props.change('createNewDelivery', true)
  },

  handleCreateCancel(e: Event) {
    e.preventDefault()
    this.props.change('website_configuration_attributes', null)
    this.props.change('createNewDelivery', false)
  },

  renderAddConfigurationsLink() {
    return (
      <span>
        <em>Don&apos;t see what you are looking for?</em>
        &nbsp;
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a onClick={this.handleCreateNew}>Add a new website configuration</a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </span>
    )
  },

  render() {
    const {
      collectionMethodType,
      collectionMethodValue,
      createNewDelivery,
      websiteConfigurations,
    } = this.props
    if (createNewDelivery) {
      return (
        <div>
          <FormGroup label="Name*" labelCols={3} controlCols={9}>
            <InputField
              name="website_configuration_attributes.name"
              component="input"
              type="text"
              className="form-control"
              autoFocus
            />
          </FormGroup>

          <FormGroup label="URL*" labelCols={3} controlCols={9}>
            <InputField
              name="website_configuration_attributes.url"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup label="User Name" labelCols={3} controlCols={9}>
            <InputField
              name="website_configuration_attributes.user_name"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup label="Password" labelCols={3} controlCols={9}>
            <InputField
              name="website_configuration_attributes.password"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup labelCols={3} controlCols={9}>
            <button
              id="existing"
              type="button"
              className="btn btn-link"
              onClick={this.handleCreateCancel}
            >
              Use Existing Website
            </button>
          </FormGroup>
        </div>
      )
    }

    const site =
      websiteConfigurations &&
      websiteConfigurations.ready &&
      _.find(websiteConfigurations.payload.data, { id: collectionMethodValue })

    return (
      <div>
        <div className="form-group">
          <div className="col-xs-6 col-xs-offset-3">
            <ControlledField
              name="collectionMethodValue"
              component={SiteDropDown}
              disabled={!collectionMethodType}
              noResultsText={this.renderAddConfigurationsLink()}
              style={styles.select}
              placeholder="Select site configuration..."
            />
          </div>
          <div className="col-xs-3">
            <button
              id="add"
              type="button"
              className="btn btn-default pull-right"
              onClick={this.handleCreateNew}
            >
              <i className="fa fa-plus" /> Add Site
            </button>
          </div>
        </div>

        {site && (
          <div className="well" style={styles.well}>
            <DeliveryDetailLine label="URL" field={site.url} />
            {site.user_name && (
              <DeliveryDetailLine label="User Name" field={site.user_name} />
            )}
            {site.password && (
              <DeliveryDetailLine label="Password" field={site.password} />
            )}
          </div>
        )}
      </div>
    )
  },
})

const selector = formValueSelector('dataCollectionEffortFileDetails')
export function mapStateToProps(state: any) {
  return {
    collectionMethodValue: +selector(state, 'collectionMethodValue'),
  }
}

export function mount({
  fetchWebsiteConfigurations,
  organization_id,
}: {
  fetchWebsiteConfigurations: any
  organization_id: string | number
}) {
  return fetchWebsiteConfigurations({ organization_id })
}

const resources = { websiteConfigurations }
export const ResourceBound = bindResource(resources, { mount })(
  WebsiteSelectForm
)
export default connect(mapStateToProps)(ResourceBound)
