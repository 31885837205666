import React from 'react'
import createReactClass from 'create-react-class'
import { bindResource } from 'redux-supermodel'

import FormGroup from './FormGroup'
import InputField from './Fields/InputField'
import ControlledField from './Fields/ControlledField'
import withResource from './DropDowns/ResourceDropDown'
import { emailConfigurations } from '../../lib/opsApi'

interface MountProps {
  fetchEmailConfigurations: (params: {
    organization_id: string
  }) => Promise<void>
  organization_id: string
}

interface EmailSelectFormProps {
  change: (field: string, value: any) => void
  createNewDelivery?: boolean
  collectionMethodType?: string
}

const EmailDropDown = withResource(emailConfigurations)

export const EmailSelectForm = createReactClass<EmailSelectFormProps>({
  displayName: 'EmailSelectForm',

  handleCreateNew(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    this.props.change('collectionMethodValue', null)
    this.props.change('createNewDelivery', true)
  },

  handleCreateCancel(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    this.props.change('email_configuration_attributes', null)
    this.props.change('createNewDelivery', false)
  },

  renderOptionLabel({ name, email }: { name: string; email: string }) {
    return `${name} (${email})`
  },

  renderAddConfigurationsLink() {
    return (
      <span>
        <em>Don&apos;t see what you are looking for?</em>
        &nbsp;
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a onClick={this.handleCreateNew}>Add a new email configuration</a>
        {/* eslint-enable jsx-a11y/anchor-is-valid */}
      </span>
    )
  },

  render() {
    const { collectionMethodType, createNewDelivery } = this.props
    if (createNewDelivery) {
      return (
        <div>
          <FormGroup label="Name*" labelCols={3} controlCols={9}>
            <InputField
              name="email_configuration_attributes.name"
              component="input"
              type="text"
              className="form-control"
              autoFocus
            />
          </FormGroup>

          <FormGroup label="Email*" labelCols={3} controlCols={9}>
            <InputField
              name="email_configuration_attributes.email"
              component="input"
              type="text"
              className="form-control"
            />
          </FormGroup>

          <FormGroup labelCols={3} controlCols={9}>
            <button
              id="existing"
              type="button"
              className="btn btn-link"
              onClick={this.handleCreateCancel}
            >
              Use Existing Email
            </button>
          </FormGroup>
        </div>
      )
    }

    return (
      <div className="form-group">
        <div className="col-xs-6 col-xs-offset-3">
          <ControlledField
            name="collectionMethodValue"
            component={EmailDropDown}
            label={this.renderOptionLabel}
            disabled={!collectionMethodType}
            noResultsText={this.renderAddConfigurationsLink()}
            placeholder="Select email configuration..."
          />
        </div>
        <div className="col-xs-3">
          <button
            id="add"
            type="button"
            className="btn btn-default pull-right"
            onClick={this.handleCreateNew}
          >
            <i className="fa fa-plus" /> Add Email
          </button>
        </div>
      </div>
    )
  },
})

export function mount({
  fetchEmailConfigurations,
  organization_id,
}: MountProps) {
  return fetchEmailConfigurations({ organization_id })
}

const resources = { emailConfigurations }
export default bindResource(resources, { mount })(EmailSelectForm)
