import { compose, withProps } from 'recompose'
import { Radio, RadioProps } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'

const RadioField = compose<RadioProps, any>(
  withProps(({ value, input }: { value: any; input: any }) => {
    return {
      ...input,
      // Create proprty `checked`.
      checked: value === input.value,
      value,
    }
  }),
  withFeedback
)(Radio as any)

// Assign the enhanced component to Checkbox.Field
;(Radio as any).Field = RadioField

export default Radio
