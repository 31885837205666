import React, { ReactNode } from 'react'
import { Modal } from 'react-bootstrap'

interface AcknowledgeDialogProps {
  title: string
  body: string | ReactNode
  show: boolean
  onConfirm: () => void
  confirmLabel?: string
  showTopCloseButton?: boolean
  isLarge?: boolean
}

export default function AcknowledgeDialog(props: AcknowledgeDialogProps) {
  const {
    show,
    onConfirm,
    title,
    body,
    confirmLabel = 'OK',
    showTopCloseButton = false,
    isLarge = false,
  } = props

  const bsSize = isLarge ? 'large' : undefined
  const ariaLabelledby = isLarge ? 'contained-modal-title-lg' : undefined

  return (
    <Modal
      show={show}
      onHide={onConfirm}
      bsSize={bsSize}
      aria-labelledby={ariaLabelledby}
    >
      <Modal.Header closeButton={showTopCloseButton}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" autoFocus onClick={onConfirm}>
          {confirmLabel}
        </button>
      </Modal.Footer>
    </Modal>
  )
}
