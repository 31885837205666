import React from 'react'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { currentUser } from '../lib/opsApi'
import History from '../History'
import { ResourceState, User } from './types'

interface LoadingProps {
  currentUser: ResourceState<User>,
  login: {
    pathname: string,
    search: string,
  },
}

export const Loading = () => (
  <h1><i className='fa fa-spin fa-refresh' /> Loading...</h1>
)

export const mapProps = (state: any) => ({ currentUser: currentUser(state), login: state.login })

export function componentDidMount() {
  const { currentUser } = this.props as LoadingProps
  if (!currentUser.busy && !currentUser.initialized) {
    History.replace('/')
  }
}

export function componentDidUpdate(prevProps: LoadingProps) {
  const { currentUser, login } = this.props as LoadingProps
  if (!currentUser.initialized && prevProps.currentUser.initialized) {
    const { pathname, search } = login
    History.replace(pathname + search)
  }
}

export default compose<LoadingProps, {}>(
  connect(mapProps),
  lifecycle({ componentDidMount, componentDidUpdate })
)(Loading)
