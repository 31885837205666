import isArray from 'lodash/isArray'
import { ReactElement } from 'react'
import { compose, branch, renderNothing } from 'recompose'
import { User } from '../types'
import withCurrentUser from './withCurrentUser'

interface HasFeatureProps {
  children: ReactElement
  feature?: string | string[]
  currentUser?: User
}

export const HasFeature = (props: HasFeatureProps) => props.children

export default compose<HasFeatureProps, any>(
  withCurrentUser,
  branch(({ currentUser, feature: features = [] }: HasFeatureProps) => {
    if (!isArray(features)) {
      features = [features]
    }

    return (
      !currentUser ||
      features.every((feature: any) => !currentUser.features.includes(feature))
    )
  }, renderNothing)
)(HasFeature)
