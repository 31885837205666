import React from 'react'
import { Provider } from 'react-redux'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'
import { getAuthToken } from '../lib/localStorage'
import Store from '../Store'
import Config from '../Config'

const client = new ApolloClient({
  uri: `${Config.opsServiceHost()}/graphql`,
  request: async (operation) => {
    operation.setContext({
      headers: {
        'X-Auth-Token': getAuthToken(),
      },
    })
  },
})

interface AppProviderProps {
  children: React.ReactNode,
}

export default function AppProvider({ children }: AppProviderProps): React.JSX.Element {
  return (
    <ApolloProvider client={client}>
        <Provider store={Store}>
          <React.Suspense
            fallback={
              <h1 className="text-center">
                <i className="fa fa-refresh fa-spin" />
              </h1>
            }
          >
            {children}
          </React.Suspense>
        </Provider>
    </ApolloProvider>
  )
}

