import { ReactNode, useEffect } from 'react'
import withCustomBreadcrumb from './withCustomBreadcrumb'

interface BreadcrumbProps {
  setBreadcrumb: (breadcrumb: ReactNode) => void
  children: ReactNode
}

export function Breadcrumb({ setBreadcrumb, children }: BreadcrumbProps) {
  useEffect(() => {
    setBreadcrumb(children)
  }, [setBreadcrumb, children])

  return null
}

const result = withCustomBreadcrumb(Breadcrumb) as any
result.BaseComponent = Breadcrumb
export default result
