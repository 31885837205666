import { Component, ReactNode } from 'react'
import { func, number, shape } from 'prop-types'
import { connect } from 'react-redux'
import { versionSelector } from '../../redux/reducers/pages'

//TODO : as it is imported in other components it can be removed after those components migrated to TS
export const dataContextShape = {
  getObjectAt: func.isRequired,
  version: number.isRequired,
}

interface DataContextProps {
  getObjectAt: (index: number) => any
  version: number
  children: ReactNode
}

export class DataContext extends Component<DataContextProps> {
  static childContextTypes = {
    data: shape(dataContextShape),
  }

  getChildContext = () => {
    const { getObjectAt, version } = this.props
    return { data: { getObjectAt, version } }
  }

  render = () => this.props.children
}

export const mapProps = (state: any) => ({ version: versionSelector(state) })

export default connect(mapProps)(DataContext)
