import React from 'react'
import { compose, flattenProp } from 'recompose'
import { FormControl, FormControlProps } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'


const FormControlField = compose<FormControlProps, any>(
    flattenProp('input'),
  withFeedback
  )(FormControl as any);
  
  // Assign the enhanced component to Checkbox.Field
  (FormControl as any).Field = FormControlField;

(FormControl.Static as any).Field = ({input, ...rest}:{input:HTMLFormElement}) => (
  <FormControl.Static {...rest} children={input.value} />
)

export default FormControl
