import React from 'react'
import md5 from 'md5'

interface GravatarProps {
  /** The email address to locate the gravatar profile for */
  email: string
  /** Size of the gravatar */
  size?: number
}

/** Display an email address as a gravatar */
export default function Gravatar({ email = '', size = 50 }: GravatarProps) {
  const gravatar = `https://www.gravatar.com/avatar/${md5(
    email
  )}?d=mm&size=${size}`
  return (
    <img
      src={gravatar}
      alt=""
      className="img-rounded"
      style={{ width: size, height: size }}
    />
  )
}
