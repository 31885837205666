import React from 'react'
import * as BS from 'react-bootstrap'
import capitalize from 'lodash/capitalize'
import { Meta } from './ValidatedField'
const checkStyle = { marginLeft: 10 }

interface CheckboxGroupFieldProps {
  input: HTMLFormElement
  meta: Meta
  hideValidation: boolean
  disabled: boolean
  options: BS.CheckboxProps[]
}

interface CheckboxOptionProps {
  index: number
  input: HTMLFormElement
  option: BS.CheckboxProps
  disabled?: boolean
}

export class CheckboxOption extends React.Component<CheckboxOptionProps> {
  handleChange = (e: any) => {
    const {
      input: { value: inputValue, onBlur, onChange },
      option: { value },
    } = this.props

    const arr = [...inputValue]
    if (e.target.checked) {
      arr.push(value)
    } else {
      arr.splice(arr.indexOf(value), 1)
    }

    onBlur(arr)
    return onChange(arr)
  }

  render() {
    const {
      input: { name, value: inputValue, onFocus },
      option: { label, value, disabled },
      index,
      disabled: fieldDisabled,
    } = this.props

    return (
      <BS.Checkbox
        key={index}
        name={`${name}[${index}]`}
        value={value}
        checked={inputValue.includes(value)}
        onChange={this.handleChange}
        onFocus={onFocus}
        disabled={disabled || fieldDisabled}
        style={checkStyle}
        inline
      >
        {label}
      </BS.Checkbox>
    )
  }
}

export default function CheckboxGroup(
  input: HTMLFormElement,
  { options = [] }
) {
  return options.map((option: BS.Checkbox.CheckboxProps, index: number) => (
    <CheckboxOption index={index} input={input} option={option} />
  ))
}

CheckboxGroup.Field = ({
  input,
  meta,
  hideValidation = false,
  disabled = false,
  options = [],
}: CheckboxGroupFieldProps) => {
  const boxes = options.map(
    (option: BS.Checkbox.CheckboxProps, index: number) => (
      <CheckboxOption
        key={index}
        input={input}
        option={option}
        index={index}
        disabled={disabled}
      />
    )
  )

  return (
    <>
      {boxes}

      {!hideValidation && meta.touched && (
        <div>
          {meta.error && (
            <div className="text-danger">
              {capitalize(meta.error as string)}
            </div>
          )}
          {meta.warning && (
            <div className="text-warning">
              {capitalize(meta.warning as string)}
            </div>
          )}
        </div>
      )}
    </>
  )
}
