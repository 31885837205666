import React from 'react'
import Snackbar from './Snackbar'

interface ErrorAlertProps {
  error: Error | null
}

export default function ErrorAlert({ error }: ErrorAlertProps) {
  const [showing, setShowing] = React.useState(error != null)

  React.useEffect(() => {
    setShowing(error != null)
  }, [error])

  return (
    <Snackbar show={showing} type="danger" onClose={() => setShowing(false)}>
      {getMessage(error)}
    </Snackbar>
  )
}

function getMessage(error: Error | null) {
  if (!error) return null

  let msg = error.message
  msg = msg.replace(/^\s*GraphQL error:\s*/im, '')
  msg = msg.replace(/^\s*A business error occurred:\s*/im, '')
  return msg
}
