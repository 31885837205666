import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip as RBTooltip } from 'react-bootstrap'
import classNames from 'classnames'

// OverlayTrigger doesn't allow the tooltip to be disabled, even for empty string;
// but rendering OverlayTrigger conditionally causes the input to lose focus when
// the title changes because the existing component unmounts. As a workaround,
// swap out the tooltip for a replacement that renders nothing.
export class NullTooltip extends RBTooltip {
  render() {
    return null
  }
}

interface TooltipCustomProps {
  id?: string
  title?: string
  iconType?: string
  className?: string
  placement?: string
  style?: any
  tooltipSize?: any
  children?: ReactNode
  hide?: boolean
  contentsFirst?: any
}

export default function Tooltip(props: TooltipCustomProps) {
  const {
    id,
    title,
    iconType,
    className,
    placement,
    style,
    tooltipSize,
    children,
    hide = false,
    contentsFirst,
    ...rest
  } = props

  const tooltipClasses = classNames({
    wide: tooltipSize === 'wide',
    hide: hide,
  })

  const tooltip = title ? (
    <RBTooltip id={id} className={tooltipClasses}>
      {title}
    </RBTooltip>
  ) : (
    <NullTooltip id={id} />
  )

  let contents
  if (iconType) {
    const icon = <i key="icon" className={`fa fa-${iconType}`} />
    if (contentsFirst) {
      contents = [children, ' ', icon]
    } else {
      contents = [icon, ' ', children]
    }
  } else {
    contents = [children]
  }

  return (
    <OverlayTrigger
      placement={placement || 'bottom'}
      overlay={tooltip}
      {...rest}
    >
      <span className={className} style={style}>
        {contents}
      </span>
    </OverlayTrigger>
  )
}
