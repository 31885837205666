import { GQLNotification, NotificationType } from "./types"

export default function createAlertUrl(notification: GQLNotification) {
  if (!notification || !notification.alert || !notification.alert.data) {
    return '/'
  }

  const { type, data } = notification.alert
  const { id, tab } = data

  switch (type) {
    case NotificationType.CLIENT_PRODUCT_ACTIVITY:
    case NotificationType.CLIENT_PRODUCT_COMMENT:
      return `/datacollection/requests/${data.product_request_id}/${tab ||
        'history'}`
    case NotificationType.DATA_COLLECTION_PRODUCT_REQUEST:
      return `/datacollection/requests/${id}/${tab || 'properties'}`
    case NotificationType.DATA_COLLECTION_EFFORT:
    case NotificationType.DATA_COLLECTION_EFFORT_DUE_DATE:
      return `/datacollection/efforts/${id}/${tab || 'summary'}`
    case NotificationType.FIM_DATA_COLLECTION_EFFORT_DUE_DATE:
      return `/datacollection/efforts/${id}/details`
    case NotificationType.MACHINE_READABLE_FILE_SUBMIT_REQUEST:
      return `/harvesting/machinereadablefilemaps/${data.map_id || id}/history`
    case NotificationType.NETWORK_MAPPING:
      return `/dataprocessing/networkmappings/${id}/${tab || 'properties'}`
    case NotificationType.PROJECT_MAPPING:
      return `/dataprocessing/projectmappings/${id}/${tab || 'properties'}`
    case NotificationType.CLIENT_PRODUCT:
      return `/datacollection/products/${id}/${tab || 'properties'}`
    case NotificationType.DCE_GENERATION_TRANSACTION:
      return `/datacollection/efforts/${
        data.data_collection_effort_id
      }/generations/${data.generation_id}`
    case NotificationType.PRODUCT_REQUEST_RECEIVED:
      return `/datacollection/requests/${data.request_id}/properties`
    case NotificationType.COMMENT_MODERATION:
      return '/moderatedComments/new'
    case NotificationType.NEXT_ACTION_REMINDER:
      return `/datacollection/products/${
        data.client_product_id
      }/requests/${id}/properties`
    case NotificationType.NEXT_ACTION_REMINDER_MEDICARE_ADVANTAGE:
      return { pathname: "/datacollection/products", query: { type: "in_progress_ops" } }
    case NotificationType.CARRIER_APPROVAL_REQUEST_COMMENT:
      return `/dataprocessing/carrierapprovalrequests/${data.request_id}`
    default:
      return '/'
  }
}
