import _ from 'lodash'
import React from 'react'
import toastr from 'toastr'

interface SubmitErrorProps {
  error: any
  title: string
}

export function getMessage(err: any) {
  return _.join(err, '')
}

export default class SubmitError extends React.PureComponent<SubmitErrorProps> {
  static defaultProps = {
    title: 'Failure',
  }

  componentDidMount() {
    const { error, title } = this.props
    if (error) {
      toastr.error(getMessage(error), title)
    }
  }

  componentDidUpdate(prevProps: SubmitErrorProps) {
    const { error, title } = this.props
    if (error && prevProps.error !== error) {
      toastr.error(getMessage(error), title)
    }
  }

  render() {
    const { error } = this.props
    if (error) {
      const msg = getMessage(error)
      return msg ? <span className="text-danger">{msg}</span> : null
    }

    return null
  }
}
