import React from 'react'

export default function RouteNotFound(): React.JSX.Element {
  return (
    <div className='jumbotron'>
      <h1>Sorry, this page was not found.</h1>
      <p>Please contact support.</p>
    </div>
  )
}
