import React from 'react'
import _ from 'lodash'

const style = {
  backgroundColor: '#FFFF88',
  color: '#FF0000',
}

interface MatchHighlightProps {
  children: any
  match?: string
}

export function MatchHighlight({ children, match = '' }: MatchHighlightProps) {
  const value = children.toString()
  const matchResults = match && match.toLowerCase()
  const parts = match.length
    ? value.split(new RegExp(`(${_.escapeRegExp(match)})`, 'ig'))
    : [value]

  return (
    <>
      {parts.map((part: any, index: number) =>
        part && String(part).toLowerCase() === matchResults ? (
          <span key={index} style={style}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  )
}

export default function withHighlight({
  text,
  search,
}: {
  text: string
  search: string
}) {
  if (!search) {
    return text
  }

  const pattern = `(.*)(${_.escapeRegExp(search)})(.*)`
  const reg = new RegExp(pattern, 'i')
  const results = reg.exec(text)
  if (!results) {
    return text
  }

  const [, pre, highlight, post] = results
  return [
    pre,
    <span key="highlight" style={style}>
      {highlight}
    </span>,
    post,
  ]
}
